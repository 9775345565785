import { bootstrapApplication } from '@angular/platform-browser';
import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/angular-ivy';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { HOTJAR_SITE_ID, HOTJAR_VERSION, SENTRY_DSN } from './config/config';
import { environment } from './config/environment';

Sentry.init({
  enabled: window.location.hostname !== 'localhost',
  dsn: SENTRY_DSN,
  environment: environment.environment,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
});

if (['stage', 'prod'].includes(environment.environment)) {
  Hotjar.init(HOTJAR_SITE_ID, HOTJAR_VERSION);
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  // eslint-disable-next-line no-console
  console.error(err),
);
