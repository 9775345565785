import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxAddress, OnyxAddressService, OnyxPaginated } from '@onyx/angular';
import { map, Observable, of, tap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends ApiService implements OnyxAddressService {
  private lastSearches: OnyxAddress[] = [];

  constructor(protected override http: HttpClient) {
    super(http);
  }

  public geocode(
    query: string,
    limit: number | undefined = 5,
  ): Observable<OnyxPaginated<OnyxAddress>> {
    return this.get(`/geocode?query=${query}&limit=${limit}`);
  }

  public addToLastSearches(address: OnyxAddress): Observable<void> {
    return of(address).pipe(
      tap(() => {
        this.lastSearches.unshift(address);
        this.lastSearches = this.lastSearches.slice(0, 5);
      }),
      map(() => undefined),
    );
  }

  public getLastSearches(): Observable<OnyxAddress[]> {
    return of(this.lastSearches);
  }
}
