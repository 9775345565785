import { DecimalPipe, registerLocaleData } from '@angular/common';
import {
  HttpBackend,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  ONYX_HERE_MAPS_API_KEY,
  ONYX_TRANSLATIONS_SOURCE,
  OnyxAddressService,
  OnyxDatePipe,
  OnyxDictionariesService,
  OnyxLanguagePipe,
  OnyxPreferencesService,
  OnyxStorageService,
} from '@onyx/angular';
import * as Sentry from '@sentry/angular-ivy';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { GLOBAL_API_NAMESPACE, I18N_NAMESPACES } from '../config/config';
import { environment } from '../config/environment';
import { ROUTES } from './app.routes';
import { authInterceptor } from './auth/common/interceptors/auth.interceptor';
import { AddressService } from './common/services/address.service';
import { DictionariesService } from './common/services/dictionaries.service';
import { PreferencesService } from './common/services/preferences.service';
import { StorageService } from './common/services/storage.service';

registerLocaleData(localePl);

const translationsApiUrl = new URL(environment.apiUrl);
translationsApiUrl.host = `${GLOBAL_API_NAMESPACE}.${translationsApiUrl.host}`;

export const createTranslateLoader = (http: HttpBackend) => {
  return new MultiTranslateHttpLoader(http, [
    ONYX_TRANSLATIONS_SOURCE,
    ...I18N_NAMESPACES.map((namespace) => ({
      prefix: `assets/i18n/${namespace}/`,
      suffix: '.json',
    })),
    { prefix: `${translationsApiUrl}/dictionaries/`, suffix: ' ' },
  ]);
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(ROUTES),
    provideHttpClient(withInterceptors([authInterceptor])),

    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: 'pl',
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpBackend],
        },
      }),
    ]),

    { provide: LOCALE_ID, useValue: 'pl-PL' },

    DecimalPipe,
    OnyxDatePipe,
    OnyxLanguagePipe,

    // ONYX Angular Kit
    { provide: OnyxAddressService, useClass: AddressService },
    { provide: OnyxDictionariesService, useClass: DictionariesService },
    { provide: OnyxStorageService, useClass: StorageService },
    { provide: OnyxPreferencesService, useClass: PreferencesService },
    { provide: ONYX_HERE_MAPS_API_KEY, useValue: environment.hereMapsApiKey },

    // Sentry
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => undefined,
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
