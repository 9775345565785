import { Routes } from '@angular/router';
import { AUTH_ROUTES } from './auth/auth.routes';
import { DASHBOARD_ROUTES } from './dashboard/dashboard.routes';

export const ROUTES: Routes = [
  ...AUTH_ROUTES,
  ...DASHBOARD_ROUTES,
  {
    path: '**',
    redirectTo: 'login',
  },
];
